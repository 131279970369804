import React, { useEffect } from 'react';

import Input from 'components/Input';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import FormContainer from 'components/LoanForm/FormContainer';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { getMessageForRequiredFields } from 'utils/errors';
import { getFullName, setFirstAndLastName } from 'handlers/yourName';
import { AboutYouVariable } from 'enums/LoanFormVariables';
import Button from 'components/Button';
import { getYourNameData } from 'selectors/yourName';
import { useLocation } from 'react-router-dom';

import styles from './YourName.module.scss';

export enum YourNameInputLabel {
  FirstName = 'First Name',
  LastName = 'Last Name',
  Credentials = 'Credentials',
}

export interface YourNameState {
  isSessionExpired?: boolean;
}

const YourName = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const location = useLocation();
  const isSessionExpired = (location.state as YourNameState)?.isSessionExpired;

  const dispatch = useDispatch();

  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);

  const { isFirstName, isLastName } = useSelector((state: RootState) => state.userPayrollData);

  const {
    formState: { errors, isValid },
    trigger,
    register,
    watch,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: firstName,
      last_name: lastName,
    },
  });
  const watcher = watch();

  const handleContinue = () => {
    dispatch(
      setFirstAndLastName({
        [AboutYouVariable.FirstName]: watcher[AboutYouVariable.FirstName],
        [AboutYouVariable.LastName]: watcher[AboutYouVariable.LastName],
      }),
    );
    analytics.identify({
      firstName: watcher[AboutYouVariable.FirstName],
      lastName: watcher[AboutYouVariable.LastName],
      name: getFullName(watcher[AboutYouVariable.FirstName], watcher[AboutYouVariable.LastName]),
    });
    handleNext();
  };

  useEffect(() => {
    register(AboutYouVariable.FirstName, {
      required: getMessageForRequiredFields(YourNameInputLabel.FirstName),
    });
    register(AboutYouVariable.LastName, {
      required: getMessageForRequiredFields(YourNameInputLabel.LastName),
    });
  }, [register, watcher]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value.trim());
    trigger(event.target.name as AboutYouVariable);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value);
    trigger(event.target.name as AboutYouVariable);
  };

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="What's your name?" subtitle="Nice to meet you!">
        {isSessionExpired && (
          <div className={styles.sessionExpired}>Sorry, your session is expired. Please reapply.</div>
        )}

        <div className={styles.inputs}>
          <Input
            label={YourNameInputLabel.FirstName}
            placeholder="First Name"
            errorMessage={errors[AboutYouVariable.FirstName]?.message}
            className={styles.formInput}
            name={AboutYouVariable.FirstName}
            onBlur={onBlur}
            onChange={onChange}
            value={watcher[AboutYouVariable.FirstName]}
            disabled={isFirstName}
            data-neuro-label="firstName"
            autoComplete="given-name"
            onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
          />
          <Input
            label={YourNameInputLabel.LastName}
            placeholder="Last Name"
            errorMessage={errors[AboutYouVariable.LastName]?.message}
            className={styles.formInput}
            name={AboutYouVariable.LastName}
            onBlur={onBlur}
            onChange={onChange}
            value={watcher[AboutYouVariable.LastName]}
            disabled={isLastName}
            data-neuro-label="lastName"
            autoComplete="family-name"
            onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
          />
        </div>

        <Button disabled={!isValid} onClick={handleContinue} className={styles.button}>
          Next
        </Button>
      </FormContainer>
    </div>
  );
};

export default YourName;
