import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useQueryParam from 'hooks/useQueryParam';
import { UrlParams } from 'enums/UrlParams';
import Button from 'components/Button';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import { ReactComponent as RoadSign } from 'images/road-sign.svg';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import { FlowComponentType } from 'routes/FlowRouter';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getStudentLoanApplicationPaymentStatus } from 'thunks';
import StateContainer from 'components/StateContainer';
import { StudentLoanPayingResult } from 'enums/StudentLoanForgivenessFlowResults';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { StudentLoanAssistancePaymentStatus } from 'handlers/applicationData';
import { ConversionType, trackConversion } from 'utils/analytics';

import styles from './PaymentResult.module.scss';

enum StripeResult {
  Success = 'success',
  Cancel = 'cancel',
}

const MAX_POLL_COUNT = (2 * 60) / 5; // 2 minutes

const PaymentResult = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId } = useSelector(getStudentLoanData);
  const [paymentStatus, setPaymentStatus] = useState<StudentLoanAssistancePaymentStatus>();
  const [intervalValue, setIntervalValue] = useState<NodeJS.Timeout | null>(null);
  const [intervalCount, setIntervalCount] = useState(0);
  const result = useQueryParam(UrlParams.StripeResult);

  const pollPaymentStatus = async () => {
    try {
      const paymentState = await dispatchWithUnwrap(getStudentLoanApplicationPaymentStatus(applicationId!));
      setPaymentStatus(paymentState.status);
      if (
        paymentState.status === StudentLoanAssistancePaymentStatus.Active ||
        paymentState.status === StudentLoanAssistancePaymentStatus.Cancelled
      ) {
        clearInterval(intervalValue!);
      }
      setIntervalCount((count) => count + 1);
    } catch (error) {
      clearInterval(intervalValue!);
      setPaymentStatus(StudentLoanAssistancePaymentStatus.Cancelled);
    }
  };

  const handlePaid = () => {
    trackConversion(ConversionType.StudentLoanPayment);
    handleNext(StudentLoanPayingResult.Success);
  };

  const handleFailed = () => {
    handleNext(StudentLoanPayingResult.Failure);
  };

  useEffect(() => {
    if (result === StripeResult.Cancel) {
      setPaymentStatus(StudentLoanAssistancePaymentStatus.Cancelled);
      return;
    }

    const interval = setInterval(() => {
      pollPaymentStatus();
    }, 5000);

    setIntervalValue(interval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (intervalCount >= MAX_POLL_COUNT) {
      clearInterval(intervalValue!);
      setPaymentStatus(StudentLoanAssistancePaymentStatus.Cancelled);
    }
  }, [intervalCount]);

  switch (paymentStatus) {
    case StudentLoanAssistancePaymentStatus.Setup:
      return (
        <div className={styles.container}>
          <StateContainer icon={<LoadingSpinner />} title="Payment in progress" />
        </div>
      );
    case StudentLoanAssistancePaymentStatus.Active:
      return (
        <>
          <FormNavigation {...navigationInfo} showBackLink={false} />
          <FormContainer
            icon={<CheckIcon />}
            title="Payment Successful"
            subtitle="Let's finish the rest of your application."
          >
            <Button className={styles.button} onClick={handlePaid}>
              Continue
            </Button>
          </FormContainer>
        </>
      );
    case StudentLoanAssistancePaymentStatus.Cancelled:
      return (
        <>
          <FormNavigation {...navigationInfo} />
          <FormContainer
            icon={<RoadSign />}
            title="Payment Failed"
            subtitle="Please try again or contact us if you still have issues."
          >
            <Button className={styles.button} onClick={handleFailed}>
              Try Again
            </Button>
          </FormContainer>
        </>
      );
    default:
      return (
        <div className={styles.container}>
          <StateContainer icon={<LoadingSpinner />} title="Checking payment status" />
        </div>
      );
  }
};

export default PaymentResult;
